import React from 'react';
import Helmet from 'react-helmet';
import { Link, } from 'gatsby';
import { media } from '../utils/media';
import styled from 'styled-components';
import PageProps from '../models/PageProps';
import { Layout, Wrapper, Header, SectionTitle } from '../components';
import config from '../../config/SiteConfig';

const SinglePage = styled.main`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-size: cover;
  @media ${media.tablet} {
    flex-direction: row;
  }
  @media ${media.phone} {
    height: 100%;
    flex-direction: column;
  }
`;

const GridRow: any = styled.div`
  flex: ${(props: any) => (props.image ? 1 : 2)};
  display: flex;
  justify-content: center;
  min-height: 100%;
  align-items: center;
  overflow: hidden;
  background-image: ${(props: any) => (props.image ? 'url(/assets/linnea_skaapet.jpg) no-repeat;' : '')}
  background-size: cover;
  padding: 2rem 4rem;
  color: ${(props: any) => (props.background ? props.theme.colors.white : props.theme.colors.white)};
  h1, h3 {
    color: ${(props: any) => (props.background ? props.theme.colors.white : props.theme.colors.white)};
  }
  a {
    color: ${(props: any) => (props.background ? props.theme.colors.modio.light : props.theme.colors.modio.light)};
  }
  div {
    position: relative;
  }
  
  h3#greenBlock {
    background-color: ${(props: any) => props.background ? (props.theme.colors.modio.light): (props.theme.colors.modio.light)};
    display: block;
    margin: 0 -6rem;
    padding: 1rem 6rem;
    color ${(props: any) => props.background ? (props.theme.colors.modio.dark) : (props.theme.colors.modio.dark)};
  }
  .padd {
    padding: 4rem 0 10rem 0;
  }
  img.inline {
    width: 45%;
    height: auto;
    float: right;
    margin-right: -6rem;
    margin-top: -3rem;
  }
  @media ${media.tablet} {
    padding: 2rem 2rem;
    img.inline {
      margin-right: -4rem;
    }
  }
  @media ${media.phone} {
    padding: 2rem 1.5rem;
  }
  .skaapet {
    background-color: #fff;
    margin: 0 -6rem;
    padding: 3rem 6rem 14rem;
    box-sizing: initial;
    border-top-left-radius: 2vw;
    color: ${(props: any) => (props.background ? props.theme.colors.modio.dark : props.theme.colors.modio.dark)};
    h1, h3 {
      color: ${(props: any) => (props.background ? props.theme.colors.modio.dark : props.theme.colors.modio.dark)};
    }
    a {
      color: ${(props: any) => (props.background ? props.theme.colors.modio.dark : props.theme.colors.modio.dark)};
    }
    
  }
  .padd2 {
    padding: 4rem 0 20rem 0 !important;
  }
 
`;

const SinglePageContent: any = styled.div`
  max-width: 100%;
  text-align: ${(props: any) => (props.center ? 'center' : 'left')};
`;

export default class InstallatorerPage extends React.Component<PageProps> {
  public render() {
    return (
      <Layout>
        <Helmet title={`För installatörer | ${config.siteTitle}`} />
        <Header>
          <Link to="/">{config.siteTitle}</Link>
          <SectionTitle uppercase={true}>Information för installatörer</SectionTitle>
        </Header>
        <Wrapper fullWidth={true}>
          <SinglePage>
            <GridRow>
              <SinglePageContent>
                <h3>Modio SmartSolar är redo att installeras direkt.</h3>
                <h3>Skåpet ersätter elskåpet och innehåller allt som behövs för fjärravläsning och övervakning.</h3>
                <h3 id='greenBlock'>Det enda du behöver ansluta är solcellsanläggningen.</h3>
                <div className='padd'>
                  <p>Via en helt vanlig internetuppkoppling får du en säker övervakning över produktionen i alla dina installerade solcellsanläggningar. Du kan dessutom skapa grupper av installationer, så att en total produktion i flera enheter kan visas samtidigt.</p>
                  <p>Modio SmartSolar hanterar även elcertifikatsmätning, insamling och administration. Den processen tar Modio hand om, så att du som installatör helt slipper det.</p>
                  <p>Som solcellsinstallatör kan du skapa din egen kundportal där all anläggningsinformation kan samlas och göras tillgänglig för kunderna.</p>
                  <p>Det ger dig en långsiktig relation och möjlighet till merförsäljning.</p>
                </div>
                <img src='/assets/rtu.gif' className='rtu'/>
              </SinglePageContent>
            </GridRow>
            <GridRow className='padd2'>
              <SinglePageContent>
                <div className='skaapet'>
                  <img src='/assets/linnea_skaap.jpg' className='inline'/>
                  <h3>Enkel och snabb installation</h3>
                  <h4>Minskad tidsåtgång men desto snabbare återkoppling.</h4>
                  <p>Modio SmartSolar kommer som färdig produkt och ersätter det vanliga elskåp som annars måste byggas i anslutning till solcellsanläggningen.</p>
                  <p>Det innebär att installationen blir både enkel och kvalitetssäkrad. När produkten är inkopplad behöver bara installatören använda sin mobiltelefon och scanna den QR-kod som finns på produkten.</p>
                  <p>Sedan är arbetet klart.</p>
                  <h3>Kontinuerlig övervakning</h3>
                  <h4>Fortsatt relation med din kund. Om och när du vill.</h4>
                  <p>Med Modio SmartSolar får du möjligheten att skapa en långsiktig relation med slutkunden.</p>
                  <p>Du har möjlighet att, helt oberoende av installerad inverter, hela tiden följa produktionen, skapa serviceavtal med produktionslarm etc. Allt beroende på hur du vill utveckla din affär.</p>
                  <h3>Hantering av elcertifikat</h3>
                  <h4>Låt kunden få extra betalt. För all energi som produceras.</h4>
                  <p>Med Modio SmartSolar ger du kunden möjligheten att få betalt för all den energi som produceras, oavsett om kunden använder den själv eller säljer den vidare ut på elnätet.</p>
                  <p>Hanteringen av Elcertifikat sköts i sin helhet av Modio och innebär inte något extra arbete för installatören.</p>
                  <p>Bara mer pengar för kunden. — Och en attraktivare kalkyl för dig som installatör.</p>
                </div>
                
              </SinglePageContent>
            </GridRow>
          </SinglePage>
        </Wrapper>
      </Layout>
    );
  }
}